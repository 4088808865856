<template>
  <van-checkbox-group v-model="result">
    <van-checkbox
        v-for="opts in option"
        :name="opts[props.valueKey]"
        :key="opts[props.valueKey]"
        shape="rect"
    >
      {{ opts[props.textKey] }}
      <template #icon>
        <van-icon name="success"/>
      </template>
    </van-checkbox>
  </van-checkbox-group>
</template>

<script>
export default {
  name: 'CheckboxOption',
  props: {
    value: {
      default: () => [],
    },
    option: {
      default: () => [],
    },
    props: {
      default: () => ({
        valueKey: 'value',
        textKey: 'text',
      }),
    },
  },
  computed: {
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.van-checkbox {
  position: relative;
  z-index: 1;
  padding: 16px 0;

  /deep/ .van-icon.van-icon-success {
    border-radius: 4px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    left: 35px;
    bottom: 0;
    height: 0;
    border-bottom: 1px solid #ebebeb;
  }

  /deep/ .van-checkbox__label {
    margin-left: 15px;
    font-size: 15px;
  }

  /deep/ .van-checkbox__icon--checked .van-icon {
    border-color: @primary-color;
    color: @primary-color;
    background-color: #0000;
  }
}

.van-checkbox:nth-last-child(1) {
  &:after {
    content: none;
  }
}
</style>
