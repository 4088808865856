<!-- 答题 -->
<template>
  <page-container class="subject-detail-container">
    <p class="eval-name">{{ evalName }}</p>
    <div
        v-for="(ques, i) in questionList"
        class="question-card-item"
    >
      <div class="question-text">
        <span class="question-index">{{ i + 1 }}</span>
        <span class="question-type">[ {{ ques.type === QUESTION_TYPE.RADIO ? '单选' : '多选' }} ]</span>
        {{ ques.content }}
        <div v-if="ques.type === QUESTION_TYPE.CHECKBOX" class="question-text-tip">
          <van-icon name="warning"/>
          请至少选择一项
        </div>
      </div>
      <radio-option
          v-if="ques.type === QUESTION_TYPE.RADIO"
          v-model="ques.value"
          :option="ques.quesList"
      />
      <checkbox-option
          v-if="ques.type === QUESTION_TYPE.CHECKBOX"
          v-model="ques.value"
          :option="ques.quesList"
      />
    </div>
    <div class="subject-detail-bottom-bar">
      <van-button type="primary" block round @click="submit">提 交</van-button>
    </div>

    <van-overlay z-index="9" :show="examResultShow">
      <div class="exam-result-warp">
        <div class="exam-result-container">
          <div
              class="result-status"
              :class="{ isPass }"
          />
          <p v-if="isPass" class="result-title">恭喜您<br>通过此次测评</p>
          <p v-else class="result-title">很遗憾<br>您没有通过此次测评</p>
          <div class="exam-result-item">
            <label>题目数量：</label>
            <span>{{ examResult.total }}题</span>
          </div>
          <div class="exam-result-item">
            <label>正确数量：</label>
            <span>{{ examResult.rightTotal }}题</span>
          </div>
          <div class="exam-result-item">
            <label>正确率：</label>
            <span>{{ examResult.rightRate }}%</span>
          </div>
          <van-button round type="primary" @click="handleToNextPage">确 定</van-button>
        </div>
      </div>
    </van-overlay>

  </page-container>
</template>

<script>

import { QUESTION_TYPE } from '@/views/subject/const';
import RadioOption from '@/components/question/RadioOption';
import CheckboxOption from '@/components/question/CheckboxOption';
import { Dialog } from 'vant';
import flowChain from '@/util/flowChain';
import { getEvalInfo, getSubjectByCourseId, submitAnswer } from '@/api/subject'; // 获取用户当前所需要进行任务流程

const testGetSubjectDataApi = () => {
  // const valueArr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const valueArr = 'ABCDEFGHIJ'.split('');
  let flag = () => !!(~~(Math.random() * 100) % 2);
  let getLength = (min, max) => parseInt(Math.random() * (max - min + 1) + min, 10);
  let { RADIO, CHECKBOX } = QUESTION_TYPE;
  let getQues = () => {
    let quesList = [];
    let l = getLength(2, valueArr.length);
    while (l--) {
      let value = valueArr[l];
      let rStr = new Array(getLength(2, 38)).fill(value).join('');
      quesList.unshift({
        value,
        text: `选项${rStr}`
      });
    }
    return {
      content: new Array(getLength(2, 18)).fill('问题').join('') + '？',
      type: flag() ? RADIO : CHECKBOX,
      quesList,
    };
  };
  let l = getLength(2, 18);
  let questionObjList = [];
  while (l--) {
    questionObjList.unshift(getQues());
  }
  return new Promise(resolve => setTimeout(() => resolve(questionObjList), 321));
};

export default {
  name: 'subjectDetail',
  components: { CheckboxOption, RadioOption },
  data() {
    return {
      evalId: '',
      evalName: '',
      questionList: [],
      isPass: false,
      examResultShow: false,
    };
  },
  computed: {
    QUESTION_TYPE() {
      return QUESTION_TYPE;
    },
    examResult() {
      let { questionList } = this;
      let total = questionList.length;
      let rightTotal = [0, ...questionList].reduce((rightTotal, e) => {
        let { value, rightKey } = e;
        let val = Array.isArray(value) ? value.sort().join() : value;
        val === rightKey && (rightTotal += 1);
        return rightTotal;
      });
      return {
        total,
        rightTotal,
        rightRate: Math.round((rightTotal / total) * 100),
      };
    },
  },
  created() {
    let { evalId, courseId } = this.$route.query || {};
    let pms = null;
    // testGetSubjectDataApi()

    if (evalId)
      pms = getEvalInfo({ evalId });
    else if (courseId)
      pms = getSubjectByCourseId({ courseId });

    pms && pms.then(res => {
      if (res === null) {
        this.$router.back();
      }

      let { evalId, evalName, quesList } = res || {};
      let questionList = Array.isArray(quesList) ? quesList : [];
      questionList = this.addModel(questionList);
      Object.assign(this, {
        evalId,
        evalName,
        questionList,
      });
    });
  },
  methods: {
    addModel(arr) {
      return arr.map(e => {
        let value = '';
        if (e.type === QUESTION_TYPE.CHECKBOX)
          value = [];

        let keyArr = ['keyA', 'keyB', 'keyC', 'keyD'];
        let valueArr = ['A', 'B', 'C', 'D'];

        let quesList = [];
        keyArr.forEach((key, i) => {
          let text = e[key];
          if (text) {
            quesList.push({
              value: valueArr[i],
              text,
            });
          }
        });

        return { ...e, value, quesList };
      });
    },
    submit() {
      let value = this.questionList.map(({ value }) => {
        if (Array.isArray(value))
          return value.sort().join();
        else
          return value;
      });
      let incompleteIndexArr = [];
      value.forEach((e, i) => {
        !e && incompleteIndexArr.push(i + 1);
      });
      if (incompleteIndexArr.length)
        return Dialog.alert({ message: `第${incompleteIndexArr.join('、')}题尚未回答，请您完成答题。` });

      Dialog.confirm({
        title: `是否提交答题？`,
        // message: '弹窗内容',
        confirmButtonText: '确认',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            submitAnswer({
              evalId: this.evalId,
              answerJson: value.join(';'),
            }).then(res => {
              let { isPass, rate } = res || {};
              // let message = '抱歉，您没有通过此次考核，请重新学习。';
              // let isPassSubject = isPass === 'true';
              // if (isPassSubject) {
              //   message = `恭喜您通过了考试，正确率为${rate}%`;
              // }
              // Dialog
              //     .alert({ message })
              //     .then(() => {
              //       this.handleToNextPage(isPassSubject);
              //     });

              Object.assign(this, {
                isPass: isPass === 'true',
                examResultShow: true,
              });
            });
            done();
          } else {
            done();
          }
        }
      }).catch(() => {
      });
    },
    handleToNextPage(isPass) {
      let { isFlowChain } = this.$route.query;
      // 获取用户当前所需要进行任务流程
      if (isFlowChain) {
        flowChain().then(location => {
          if (location)
            this.$router.replace(location);
          else
            this.$router.replace('/home');
        });
      } else {
        this.$store.commit('homeCourse/setRefreshing', true);
        this.$router.back();
        // if (isPass) {
        //   this.$router.push({
        //     path: '/agreementDetail',
        //     query: { contractId },
        //   });
        // }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.subject-detail-container {
  padding: 0 0 90px 0;

  .eval-name {
    margin: 0;
    padding: 12px;
    line-height: 1.2;
    text-align: center;
    font-size: 14px;
  }

  .question-card-item {
    padding: 18px 16px 4px 16px;
    background-color: #fff;

    & + .question-card-item {
      margin: 15px 0 0 0;
    }
  }

  .question-text {
    margin: 0;
    padding: 0 0 15px 0;
    line-height: 24px;
    word-break: break-all;
    text-align: justify;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #ebebeb;

    .question-index {
      margin-right: 2px;
      color: #a6a6a6;
    }

    .question-type {
      margin-left: 1px;
      letter-spacing: 1px;
      color: @primary-color;
    }

    .question-text-tip {
      margin-top: 12px;
      font-size: 12px;
      color: #bababa;
    }
  }

  .subject-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 90px;

    .van-button {
      margin: 0 15px;
      font-size: 16px;
    }
  }

  .exam-result-warp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .exam-result-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px 0 25px 0;
    width: 66.66666666%;
    background-color: #fff;
    border-radius: 8px;

    .result-status {
      margin: 0 0 13px 0;
      width: 60px;
      height: 47px;
      background: url("../../assets/subject/no-pass.svg") center no-repeat;
      background-size: 100% auto;

      &.isPass {
        background: url("../../assets/subject/isPass.svg") center no-repeat;
        background-size: 100% auto;
      }
    }

    .result-title {
      margin: 0 0 29px 0;
      line-height: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: bolder;
      color: #333;
    }

    .exam-result-item {
      display: flex;
      width: 100%;
      line-height: 1;
      letter-spacing: 1px;
      font-size: 16px;
      color: #666;

      & > label {
        width: 55.8%;
        text-align: right;
      }

      & > span {
        width: 44.2%;
        text-align: left;
        letter-spacing: 2px;
      }

      & + .exam-result-item {
        margin: 14px 0 0 0;
      }
    }

    .van-button {
      margin: 25px 0 0 0;
      padding: 0 43px;
      height: 36px;
    }
  }
}
</style>
