<template>
  <van-radio-group v-model="radio" @change="handleChange">
    <van-radio
        v-for="opts in option"
        :name="opts[props.valueKey]"
        :key="opts[props.valueKey]"
        @click="handleRadioClick"
    >
      {{ opts[props.textKey] }}
<!--      <template #icon="props">-->
<!--        <van-icon-->
<!--            class-prefix="custom-radio-font"-->
<!--            :name="props.checked ? 'radio-select' : 'radio-unselect'"-->
<!--        />-->
<!--      </template>-->
    </van-radio>
  </van-radio-group>
</template>

<script>
export default {
  name: 'RadioOption',
  props: {
    value: {
      default: () => '',
    },
    option: {
      default: () => [],
    },
    props: {
      default: () => ({
        valueKey: 'value',
        textKey: 'text',
      }),
    }
  },
  data() {
    return {
      changed: false,
    };
  },
  computed: {
    radio: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleChange(e) {
      this.changed = true;
      this.$emit('change', e);
    },
    handleRadioClick() {
      if (!this.changed)
        this.radio = '';
      this.changed = false;
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: 'custom-radio-icon';
  src: url('./iconfont.ttf') format('truetype');
}

.custom-radio-font {
  font-family: "custom-radio-icon", serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 27px;
}

.custom-radio-font-radio-unselect:before {
  content: "\e6a5";
  color: #c8c9cc;
}

.custom-radio-font-radio-select:before {
  content: "\e6a6";
  color: @primary-color;
}

.van-radio {
  position: relative;
  z-index: 1;
  padding: 16px 0;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    left: 35px;
    bottom: 0;
    height: 0;
    border-bottom: 1px solid #ebebeb;
  }

  /deep/ .van-radio__label {
    margin-left: 15px;
    font-size: 15px;
  }
}

.van-radio:nth-last-child(1) {
  &:after {
    content: none;
  }
}
</style>
