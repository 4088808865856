import request from '@/plugins/axios';

/**
 * 题目详情
 */
export function getEvalInfo(params = {}) {
  return request({
    url: `school-mobile/hEvalInfo/${params.evalId}`,
    method: 'get',
    params
  });
}

/**
 * 提交答案
 * /hEvalInfo/answer
 */
export function submitAnswer(data = {}) {
  return request({
    url: `school-mobile/hEvalInfo/answer`,
    method: 'post',
    data,
  });
}

/**
 * 根据课程查询试题
 * /hEvalInfo/getInfoByCourseId/{courseId}
 */
export function getSubjectByCourseId(params = {}) {
  return request({
    url: `school-mobile/hEvalInfo/getInfoByCourseId/${params.courseId}`,
    method: 'get',
    params,
  });
}
